import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'activityCompleted',
    'backButton',
    'feedback',
    'form',
    'nextButton',
    'nextQuestionPosition',
    'nextPornRealityListPosition',
    'pornRealityList',
    'previousQuestionPosition',
    'submitButton'
  ];

  static values = {
    responseUrl: String,
    startUrl: String
  };

  connect() {
    this.currentQuestionPosition = 1;
  }

  loadNextQuestion() {
    window.inActivity = true;
    fetch(
      this.startUrlValue +
        '?porn_reality_list_position=' +
        this.nextPornRealityListPositionTarget.innerHTML +
        '&question_position=' +
        this.nextQuestionPositionTarget.innerHTML
    )
      .then((response) => response.text())
      .then((html) => (this.element.innerHTML = html));
  }

  loadPreviousQuestion() {
    window.inActivity = true;
    // hard coded position would have to change if we use a second list position
    fetch(
      this.startUrlValue +
        '?porn_reality_list_position=1&question_position=' +
        this.previousQuestionPositionTarget.innerHTML
    )
      .then((response) => response.text())
      .then((html) => (this.element.innerHTML = html));
  }

  submit() {
    this.reportResponse();
  }

  reportResponse() {
    const formData = new FormData(this.formTarget);
    Rails.ajax({
      type: 'POST',
      url: this.responseUrlValue,
      data: formData,
      success: () => {
        this.updateInterface();
      }
    });
  }

  updateInterface() {
    this.feedbackTarget.classList.remove('display-none');
    this.nextButtonTarget.classList.remove('display-none');
    this.backButtonTarget.classList.add('display-none');
    this.submitButtonTarget.classList.add('display-none');
    this.disableSlider();
  }

  disableSlider() {
    const slider = document.querySelector('.mdc-slider');
    const sliderController =
      this.application.getControllerForElementAndIdentifier(
        slider,
        'mdc--slider'
      );
    sliderController.disable();

    var sliderLabels = document.querySelectorAll('.slider-labels');
    [...sliderLabels].forEach((label) => {
      label.classList.add('disabled');
    });
  }

  activityCompletedTargetConnected() {
    window.inActivity = false;
  }
}
