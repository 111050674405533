import { Controller } from '@hotwired/stimulus';
import { MDCSlider } from '@material/slider';

export default class extends Controller {
  connect() {
    this.slider = new MDCSlider(this.element);
  }

  disable() {
    this.slider.setDisabled(true);
  }

  disconnect() {
    if (this.slider) {
      this.slider.destroy();
    }
  }
}
