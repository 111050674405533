import { Controller } from '@hotwired/stimulus';
import { MDCDialog } from '@material/dialog';

export default class extends Controller {
  static targets = ['content', 'dialog', 'title'];

  populate({ detail: { content, title } }) {
    this.contentTarget.innerHTML = content;
    this.titleTarget.innerHTML = title;
  }

  disconnect() {
    this.destroyDialog();
  }

  open() {
    this.instantiate();
    this.openDialog();
  }

  close() {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  instantiate() {
    this.dialog = new MDCDialog(this.dialogTarget);
    this.addAccessibilityListeners();
    this.addClosedListener();
  }

  openDialog() {
    this.dialog.open();
  }

  addAccessibilityListeners() {
    this.dialog.listen('MDCDialog:opened', () => {
      document.querySelector('body').setAttribute('aria-hidden', 'true');
    });
    this.dialog.listen('MDCDialog:closing', () => {
      document.querySelector('body').removeAttribute('aria-hidden');
    });
  }

  addClosedListener() {
    this.dialog.listen('MDCDialog:closed', () => {
      this.destroyDialog();
    });
  }

  destroyDialog() {
    if (this.dialog) {
      this.dialog.destroy();
      this.dialog = null;
    }
  }
}
