import { Controller } from '@hotwired/stimulus';
import { MDCTopAppBar } from '@material/top-app-bar';

export default class extends Controller {
  connect() {
    this.topAppBar = new MDCTopAppBar(this.element);
  }

  disconnect() {
    if (this.topAppBar) {
      this.topAppBar.destroy();
    }
  }
}
