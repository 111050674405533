import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'dialog'];

  connect() {
    this.dispatch('openDialog', {
      detail: {
        content: this.contentTarget.innerHTML,
        title: 'Welcome to the healthy relationships program!'
      }
    });
  }
}
