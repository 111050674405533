const searchInput = document.querySelector('[data-auto-submit-debounced]');
console.log(searchInput);
if (searchInput) {
  const debounceDelay = 250; // ms

  // Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate) {
    var timeout;

    return function executedFunction() {
      var context = this;
      var args = arguments;

      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  }

  const submitOnSearchDebounced = debounce(function () {
    searchInput.closest('form').querySelector("input[type='submit']").click();
  }, debounceDelay);

  searchInput.addEventListener('input', submitOnSearchDebounced);
}
