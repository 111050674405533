import { Controller } from '@hotwired/stimulus';
import { MDCTooltip, XPosition, YPosition } from '@material/tooltip';

export default class extends Controller {
  static targets = ['tooltip'];

  connect() {
    this.initializeTooltips();
  }

  disconnect() {
    if (this.tooltip) {
      this.tooltip.hide();
      this.tooltip.destroy();
    }
  }

  closeTooltips() {
    this.tooltip.hide();

    this.initializeTooltips();
  }

  initializeTooltips() {
    this.tooltip = new MDCTooltip(this.tooltipTarget);
    this.tooltip.setTooltipPosition({
      xPos: XPosition.END,
      yPos: YPosition.ABOVE
    });
    this.tooltip.setHideDelay(0);
  }
}
