// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// third party
import Rails from '@rails/ujs';
import '@fortawesome/fontawesome-free/css/all.min.css';

// internal
function importAll(r) {
  r.keys().forEach(r);
}
importAll(require.context('./css/', true, /.(js|scss)$/));
require.context('../images', true);
importAll(require.context('./shared', true, /.(js|scss)$/));

require('trix');
require('@rails/actiontext');

// raindrops video events
import { RDVimeoEventCapture } from 'raindrops';
import Player from '@vimeo/player';
RDVimeoEventCapture.initialize(Player, function (videoForm) {
  Rails.fire(videoForm, 'submit');
  if (videoForm.querySelector("[name='video_action[kind]']").value == 'ended') {
    const homeButton = document.querySelector('.display-when-video-completes');
    homeButton.classList.remove('display-none');
  }
});

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

window.Stimulus = Application.start();
const context = require.context('./controllers', true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

Rails.start();
