import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'activityCompleted',
    'answerFeedback',
    'form',
    'nextButton',
    'nextQuestionPosition',
    'nextVignettePosition',
    'question',
    'questionFeedback',
    'questionId',
    'submit',
    'vignette'
  ];

  static values = { startUrl: String, responseUrl: String };

  loadNextQuestion() {
    window.inActivity = true;
    fetch(
      this.startUrlValue +
        '?vignette_position=' +
        this.nextVignettePositionTarget.innerHTML +
        '&question_position=' +
        this.nextQuestionPositionTarget.innerHTML
    )
      .then((response) => response.text())
      .then((html) => (this.element.innerHTML = html));
  }

  submitText() {
    this.questionFeedbackTarget.classList.remove('display-none');
    this.submitTarget.classList.add('display-none');
    this.nextButtonTarget.classList.remove('display-none');
    this.reportTextResponse();
    this.disableForm();
  }

  submitButton({ params: { response, feedback, isCorrect, questionId } }) {
    this.answerFeedbackTarget.innerHTML = feedback;
    this.answerFeedbackTarget.classList.remove('display-none');

    if (isCorrect) {
      this.formTarget.classList.add('display-none');
      this.nextButtonTarget.classList.remove('display-none');
      this.questionFeedbackTarget.classList.remove('display-none');
    }
    this.reportButtonResponse(response, questionId);
  }

  disableForm() {
    Array.from(this.formTarget.elements).forEach((element) => {
      element.disabled = true;
    });
  }

  reportButtonResponse(response, questionId) {
    const params = new FormData();
    params.append('vignette_response[response]', response);
    params.append('vignette_response[question_id]', questionId);

    Rails.ajax({
      type: 'POST',
      url: this.responseUrlValue,
      data: params,
      success: () => {}
    });
  }

  reportTextResponse() {
    const formData = new FormData(this.formTarget);

    Rails.ajax({
      type: 'POST',
      url: this.responseUrlValue,
      data: formData,
      success: () => {}
    });
  }

  activityCompletedTargetConnected() {
    window.inActivity = false;
  }
}
