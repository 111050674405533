import { Controller } from '@hotwired/stimulus';
import { MDCSnackbar } from '@material/snackbar';

export default class extends Controller {
  static targets = ['label'];
  static values = { shouldOpenNow: Boolean };

  connect() {
    this.snackbar = new MDCSnackbar(this.element);

    if (this.shouldOpenNowValue) {
      this.open();
    }
  }

  disconnect() {
    if (this.snackbar) {
      this.snackbar.destroy();
    }
  }

  open(event) {
    if (event != null) {
      this.labelTarget.innerHTML = event.detail;
    }
    this.snackbar.open();
  }
}
