import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = { startUrl: String };

  static targets = [
    'birthControlMethod',
    'completionForm',
    'completionNavigation',
    'homeButton',
    'list'
  ];

  start() {
    window.inActivity = true;
    fetch(this.startUrlValue)
      .then((response) => response.text())
      .then((html) => (this.element.innerHTML = html))
      .then(() => {
        this.collectFinalOrder();
      });
  }

  dragStart(event) {
    this.dragElement = event.currentTarget;
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/html', event.currentTarget.innerHTML);
    // mobile devices need text data with a value set on the dataTransfer object to allow dragging
    // see https://github.com/SAP/openui5/commit/c7244b9de84aadef10d12e21b40889dbdeede640
    event.dataTransfer.setData('text', ' ');
  }

  dragOver(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    return false;
  }

  drop(event) {
    event.stopPropagation();

    if (event.currentTarget.getAttribute('draggable') == 'true') {
      // the item that has been dropped on is not in the correct spot yet
      this.reRender(event.currentTarget);
      this.checkOrder();
    }

    return false;
  }

  reRender(dropElement) {
    let listElements = document.createDocumentFragment();
    // swaps drag element and drop element
    this.birthControlMethodTargets.forEach((itemElement) => {
      if (itemElement.innerHTML == this.dragElement.innerHTML) {
        listElements.appendChild(dropElement.cloneNode(true));
      } else if (itemElement.innerHTML == dropElement.innerHTML) {
        listElements.appendChild(this.dragElement.cloneNode(true));
      } else {
        listElements.appendChild(itemElement.cloneNode(true));
      }
    });
    this.listTarget.innerHTML = null;
    this.listTarget.appendChild(listElements);
    this.captureResponse(this.dragElement, dropElement);
  }

  captureResponse(dragElement, dropElement) {
    let formData = new FormData();
    formData.append(
      'contraception_selection_response[birth_control_method]',
      dragElement.dataset.method
    );
    Rails.ajax({
      type: 'POST',
      url: this.dragElement.dataset.responseUrlValue,
      data: formData,
      dataType: 'json',
      success: () => {}
    });
  }

  checkOrder() {
    if (this.isSuccessfulRanking()) {
      this.dispatchSuccessEvent();
      this.reportCompletion();
      this.showHomeButton();
      window.inActivity = false;
    }
  }

  dispatchSuccessEvent() {
    this.completionNavigationTarget.classList.remove('display-none');
  }

  isSuccessfulRanking() {
    let percentEffective = 100;
    let successfulRanking = true;
    let position = 0;
    this.birthControlMethodTargets.forEach((itemElement) => {
      position++;
      if (itemElement.dataset.position == position) {
        itemElement
          .querySelector('.orderable-status i')
          .classList.remove('display-none');
        itemElement.setAttribute('draggable', false);
        itemElement
          .querySelector('.percent-effective')
          .classList.remove('display-none');
      } else {
        successfulRanking = false;
        itemElement
          .querySelector('.orderable-status i')
          .classList.add('display-none');
      }
    });

    return successfulRanking;
  }

  reportCompletion() {
    Rails.fire(this.completionFormTarget, 'submit');
  }

  showHomeButton() {
    this.homeButtonTarget.classList.remove('display-none');
  }

  collectFinalOrder() {
    let sortedControlMethods = [];
    let i = 0;
    this.birthControlMethodTargets.forEach((itemElement) => {
      sortedControlMethods.push({
        index: i,
        percent: itemElement.dataset.percentEffective
      });
      i++;
    });
    sortedControlMethods.sort((a, b) => {
      return b.percent - a.percent;
    });
    let position = 1;
    sortedControlMethods.forEach((sortedMethod) => {
      this.birthControlMethodTargets[sortedMethod.index].dataset.position =
        position;
      position++;
    });
  }
}
