import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'activityCompleted',
    'correctAnswer',
    'feedback',
    'firstClick',
    'question',
    'questionValue',
    'score'
  ];

  static values = {
    score: Number,
    responseUrl: String,
    startUrl: String
  };

  get currentQuestion() {
    return this.questionTarget.innerHTML;
  }

  get currentCorrectAnswer() {
    return this.correctAnswerTarget.innerHTML;
  }

  get feedback() {
    return this.feedbackTarget.innerHTML;
  }

  get currentQuestionValue() {
    return Number.parseInt(this.questionValueTarget.innerHTML);
  }

  connect() {
    this.currentQuestionPosition = 1;
  }

  loadNextQuestion() {
    window.inActivity = true;
    fetch(this.startUrlValue + '?position=' + this.currentQuestionPosition)
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
        this.currentQuestionPosition++;
      });
  }

  respond({ params: { answerId, response } }) {
    this.currentResponse = response;
    this.dispatch('respond', {
      detail: {
        content: this.formattedFeedback(),
        title: this.currentQuestion
      }
    });
    let formData = new FormData();
    formData.append('get_tested_response[answer_id]', answerId);
    Rails.ajax({
      type: 'POST',
      url: this.responseUrlValue,
      data: formData,
      success: () => {}
    });
  }

  next() {
    this.calculateScore();
    this.loadNextQuestion();
    this.firstClickTarget.dataset.clicked = 'true';
    this.dispatch('next');
  }

  formattedFeedback() {
    const correct = this.isCurrentResponseCorrect()
      ? 'Correct!'
      : `Correct answer: ${this.currentCorrectAnswer}`;
    return `<p>Your answer: ${this.currentResponse}<br>
               ${correct}</p>

            <p class="feedback">${this.feedback}`;
  }

  isCurrentResponseCorrect() {
    return this.currentResponse == this.currentCorrectAnswer;
  }

  calculateScore() {
    if (
      this.isCurrentResponseCorrect() &&
      this.firstClickTarget.dataset.clicked == 'false'
    ) {
      this.scoreValue += this.currentQuestionValue;
    }
  }

  renderScore() {
    if (this.hasScoreTarget) {
      this.scoreTarget.innerHTML = this.scoreValue.toLocaleString('en-US');
    }
  }

  scoreValueChanged() {
    this.renderScore();
  }

  scoreTargetConnected() {
    this.renderScore();
  }

  activityCompletedTargetConnected() {
    window.inActivity = false;
  }
}
